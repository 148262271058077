import styled from "styled-components"

import { NoHomeDevices } from "src/components/EmptyState/NoHomeDevices"
import { FireCOAlarmCard } from "src/components/Homes/HomeDetails/FireCOAlarm/FireCOAlarmCard"
import { HomeThermostat } from "src/components/Homes/HomeDetails/HomeThermostat/HomeThermostat"
import { IndoorClimateCard } from "src/components/Homes/HomeDetails/IndoorClimate/IndoorClimateCard"
import { NoiseMonitoringCard } from "src/components/Homes/HomeDetails/NoiseMonitoring/NoiseMonitoringCard"
import { OccupancyCard } from "src/components/Homes/HomeDetails/Occupancy/OccupancyCard"
import { NonLiveBanner } from "src/components/Homes/HomeDetails/Overview/NonLiveBanner"
import { PartialOutageCard } from "src/components/Homes/HomeDetails/Overview/PartialOutageCard"
import { WaterLeakDetectedCard } from "src/components/Homes/HomeDetails/Overview/WaterLeakDetectedCard"
import { SecurityAlarmCard } from "src/components/Homes/HomeDetails/SecurityAlarm/SecurityAlarmCard"
import { SmokingDetectionCard } from "src/components/Homes/HomeDetails/SmokingDetection/SmokingDetectionCard"
import { mediumWidth } from "src/constants/breakpoints"
import { hasIndoorClimateSupport } from "src/data/devices/logic/deviceLogic"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useFetchProfile } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { getHomeMonitoringCardVisibility } from "src/data/homes/logic/homeUtil"
import {
  getDeviceOutageState,
  reduceDeviceOutageState,
} from "src/data/homes/logic/partialOutage"
import {
  DEFAULT_HOME_NAME,
  DEFAULT_PROFILE_ID,
  THome,
} from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchNoiseMonitoringPreset } from "src/data/profileSettings/queries/monitoringPresetQueries"
import { TPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Divider } from "src/ui/Divider/Divider"
import InfoIcon from "src/ui/icons/info-i.svg"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

import { Devices } from "./Devices"

export function HomeOverview({
  home,
  loading,
  editable,
  toggleSecurityAlarmAllowed,
}: {
  home: THome
  loading: boolean
  editable: boolean
  toggleSecurityAlarmAllowed: boolean
}) {
  const { t } = useTranslate()
  const { org, orgId } = useOrganization()

  const fetchDevices = useFetchDevices({
    orgId: org?.id,
    filter: {
      home_ids: [home.home_id],
      sort: "description",
    },
    options: {
      enabled: !!org?.id,
    },
  })

  const devices = fetchDevices.data?.devices || []

  const fetchNoiseMonitoringPreset = useFetchNoiseMonitoringPreset({
    orgId,
    noisePresetId: home.noise_profile_id,
    enabled: !!home.noise_profile_id,
  })

  const devicesLoading = fetchDevices.isInitialLoading

  const presetLoading = fetchNoiseMonitoringPreset.isInitialLoading

  if (loading || devicesLoading) {
    return null
  }

  return (
    <Stack>
      <CardSection
        devices={devices}
        devicesLoading={devicesLoading}
        editable={editable}
        home={home}
        noisePreset={fetchNoiseMonitoringPreset.data}
        loading={loading || presetLoading}
        toggleSecurityAlarmAllowed={toggleSecurityAlarmAllowed}
      />

      <div style={{ display: !devices.length ? "none" : "contents" }}>
        <Divider />

        <DevicesHeader>
          <MText variant="heading2">{t(langKeys.sensor, { count: 2 })}</MText>
          <MTooltip title={t(langKeys.devices_header_tooltip)}>
            <InfoIcon width={24} />
          </MTooltip>
        </DevicesHeader>

        <SectionGrid>
          <Devices devices={devices} timezone={home.timezone} />
        </SectionGrid>
      </div>
    </Stack>
  )
}

function CardSection({
  home,
  devices,
  devicesLoading,
  loading,
  editable,
  toggleSecurityAlarmAllowed,
  noisePreset,
}: {
  home: THome
  devices: TDevice[]
  devicesLoading: boolean
  loading: boolean
  editable: boolean
  toggleSecurityAlarmAllowed: boolean
  noisePreset: TPreset | undefined
}) {
  const indoorClimateAvailable = useFeatureAvailability({
    feature: "indoor_climate_monitoring",
  }).available

  const deviceTriggeringDisturbance = devices?.find(
    (device) =>
      device.device_id ===
      home.disturbance_monitoring?.device_triggering_disturbance
  )
  const disturbanceMonitoringActive =
    !!home.disturbance_monitoring?.disturbance_monitoring_active

  const fetchProfile = useFetchProfile({
    id: home.profile_id || DEFAULT_PROFILE_ID,
  })
  const deviceOutageState = getDeviceOutageState(devices)
  const partialOutageState = reduceDeviceOutageState(deviceOutageState)

  const {
    showCrowdDetectionCard,
    showNoiseMonitoringCard,
    showSecurityAlarmCard,
    showSmokingDetectionCard,
    showCOCard,
    showIndoorClimateMonitoringCard,
  } = getHomeMonitoringCardVisibility({
    home,
    devices,
  })

  const indoorClimateDevices = devices.filter((d) => hasIndoorClimateSupport(d))

  if (devices.length === 0 && !devicesLoading) {
    return <NoHomeDevices />
  }

  if (deviceOutageState.allDevicesOffline) {
    return <NonLiveBanner />
  }

  const wldAlerting = home.water_leak_detection?.status === "alerting"

  return (
    <CardBox>
      {wldAlerting && <WaterLeakDetectedCard homeId={home.home_id} />}

      {partialOutageState !== "ok" && (
        <PartialOutageCard
          deviceOutageState={deviceOutageState}
          partialOutageState={partialOutageState}
        />
      )}

      {showNoiseMonitoringCard && home.disturbance_monitoring?.state_v2 && (
        <NoiseMonitoringCard
          homeName={home.name || DEFAULT_HOME_NAME(home.home_id)}
          homeId={home.home_id}
          monitoringState={home.disturbance_monitoring.state_v2}
          noiseDuration={
            home.disturbance_monitoring.noise_duration_seconds || 0
          }
          violations={home.violations}
          responseService={home.response_service}
          toggleAllowed={editable}
          // @ts-expect-error: noUncheckedIndexedAccess
          device={deviceTriggeringDisturbance || devices[0]}
          loading={loading}
          timezone={home.timezone}
          monitoringActive={disturbanceMonitoringActive}
          monitoringStateUpdatedAt={
            new Date(home.disturbance_monitoring.current_state_update_at)
          }
          noisePreset={noisePreset}
        />
      )}

      {showCrowdDetectionCard && <OccupancyCard home={home} />}

      {showSmokingDetectionCard && home.smoking_detection && (
        <SmokingDetectionCard
          smokingDetection={home.smoking_detection}
          toggleAllowed={editable}
          devices={devices}
          homeData={home}
        />
      )}

      {showCOCard && <FireCOAlarmCard home={home} />}

      {showIndoorClimateMonitoringCard && indoorClimateAvailable && (
        <IndoorClimateCard
          indoorClimateMonitoring={home?.indoor_climate_monitoring}
          devices={indoorClimateDevices}
          toggleAllowed={editable}
          home={home}
        />
      )}

      <HomeThermostat home={home} />

      {showSecurityAlarmCard && home.alarm && (
        <SecurityAlarmCard
          homeId={home.home_id}
          homeAlarm={home.alarm}
          canToggle={editable || toggleSecurityAlarmAllowed}
          showAlarmDelayHint={showAlarmDelayHint(
            fetchProfile.data?.security_alarm?.instantly_turn_on
          )}
          violations={home.violations}
          devices={devices}
        />
      )}
    </CardBox>
  )
}

/* We only want to show the "alarm delay" hint when it makes sense
  for the user to see it, which is when `instantly_turn_on` === `false`. Since
  `securityAlarm` can be undefined if the request hasn't finished loading yet,
  or the user doesn't have permissions to fetch profiles for this home or if
  the home is missing the security alarm object, we check that the the value
  is not undefined before
  */
function showAlarmDelayHint(instantlyTurnOn: boolean | undefined) {
  if (instantlyTurnOn === undefined) {
    return false
  }

  return !instantlyTurnOn
}

const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: ${spacing.M};
  align-items: start;
`

const CardBox = styled.div`
  display: grid;
  grid-gap: ${spacing.M};
  max-width: ${mediumWidth};
`

const Stack = styled.div`
  display: grid;
  grid-row-gap: ${spacing.XL};
`

const DevicesHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`
