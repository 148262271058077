import styled from "styled-components"

import { MonitoringPopup } from "src/components/Homes/HomeDetails/MonitoringPopup"
import { ThermostatSettings } from "src/components/Integrations/IntegrationConnectDialogs/ThermostatSettings"
import { TThermostatSettingsAnalytics } from "src/data/analytics/queries/integrationAnalyticsQueries"
import { THome } from "src/data/homes/types/homeTypes"
import {
  getCommonAvailableThermostatModes,
  useUpdateThermostatSettings,
} from "src/data/integrations/logic/integrations"
import {
  TIntegrationEntities,
  TIntegrationHomeLink,
} from "src/data/integrations/types/integrationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { MDivider } from "src/ui/Divider/Divider"
import { spacing } from "src/ui/spacing"

export function ThermostatSettingsPopover({
  home,
  homeLink,
  integrationName,
  connectedEntity,
  anchorEl,
  onClose,
  trackIntegration,
}: {
  home: THome
  homeLink: TIntegrationHomeLink
  integrationName: string
  connectedEntity?: TIntegrationEntities[number]
  anchorEl: HTMLElement | null
  onClose: () => void
  trackIntegration: TThermostatSettingsAnalytics
}) {
  const { t, langKeys } = useTranslate()

  const thermostatSettings = {
    modeOnCheckin: homeLink.thermostat_mode_on_checkin,
    commonAvailableModes: getCommonAvailableThermostatModes(
      connectedEntity?.devices
    ),
    turnOnTime: homeLink.thermostat_turn_on_in_minutes_before_checkin,
    setPoints: homeLink.thermostat_temperature_setpoint_on_checkin,
  }

  const {
    patchHome,
    handleSave,
    temperatureControlValues,
    temperatureUnit,
    setTemperatureControlValues,
    selectedMode,
    setSelectedMode,
  } = useUpdateThermostatSettings({
    modeOnCheckin: thermostatSettings.modeOnCheckin,
    setPoints: thermostatSettings.setPoints,
    turnOnTime: thermostatSettings.turnOnTime,
    homeId: home.home_id,
    afterSaveSuccess: onClose,
    trackIntegration,
  })

  return (
    <MonitoringPopup
      open={true}
      title={integrationName}
      anchorEl={anchorEl}
      description={""}
      onClose={() => {
        patchHome.reset()
        onClose()
      }}
      buttons={[]}
      error={!!patchHome.isError}
      subtitle={<StyledMDivider />}
      wide={true}
    >
      <Box>
        <ThermostatSettings
          temperatureControlValues={temperatureControlValues}
          setTemperatureControlValues={setTemperatureControlValues}
          temperatureUnit={temperatureUnit}
          selectedMode={selectedMode}
          setSelectedMode={setSelectedMode}
          commonAvailableModes={thermostatSettings.commonAvailableModes}
        />

        <ButtonBox>
          <MButton onClick={handleSave} loading={patchHome.isLoading}>
            {t(langKeys.save)}
          </MButton>
        </ButtonBox>
      </Box>
    </MonitoringPopup>
  )
}

const StyledMDivider = styled(MDivider)`
  margin: ${spacing.L} 0;
`

const Box = styled.div`
  display: grid;
  gap: ${spacing.L};
  text-align: left;
`

const ButtonBox = styled.div`
  text-align: right;
`
