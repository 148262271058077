import { useEffect, useMemo, useState } from "react"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import { startOfDay, subDays } from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"

import { FeatureBlockerText } from "src/components/FeatureBlockers/FeatureBlockerText"
import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import { GuestSMSConfigDialog } from "src/components/Homes/Guests/GuestSmsConfig"
import { extendGuestsData } from "src/components/Homes/Guests/guestsUtil"
import { Pager } from "src/components/Pager/Pager"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import {
  useDeleteGuest,
  usePostGuest,
  usePutGuest,
} from "src/data/guests/queries/guestQueries"
import {
  SortKey,
  SortOrder,
  useFetchGuests,
} from "src/data/guests/queries/guestQueries"
import {
  IGuestExtended,
  IGuestPost,
  IGuestPut,
  IGuestResponse,
} from "src/data/guests/types/guestTypes"
import { useFetchIntegrations } from "src/data/integrations/queries/integrationQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { AddButton } from "src/ui/Button/AddButton"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { GuestTable } from "./GuestTable"

export function Guests({
  homeId,
  canEdit,
  homeTz,
}: {
  homeId: string
  homeTz: string
  canEdit?: boolean
}) {
  const [selectedGuest, setSelectedGuest] = useState<
    IGuestExtended | undefined
  >(undefined)

  const [guestSmsConfigDialogOpen, setGuestSmsConfigDialogOpen] =
    useState(false)
  const [removeConfirmDialogOpen, setRemoveConfirmDialogOpen] = useState(false)
  const [upgradeBlockerDialogOpen, setUpgradeBlockerDialogOpen] =
    useState(false)

  const { t } = useTranslate()
  const { org } = useOrganization()
  const guestConnectAvailability = useFeatureAvailability({
    feature: "guest_connect",
  })

  const [offset, setOffset] = useState(0)
  const limit = 10
  const fetchGuests = useFetchGuests(homeId, {
    limit,
    offset,
    checkout_filter: {
      from: subDays(zonedTimeToUtc(startOfDay(new Date()), "Etc/GMT"), 14),
    },
    sort_options: {
      key: SortKey.CHECK_IN,
      order: SortOrder.ASCENDING,
    },
  })

  const deleteGuest = useDeleteGuest({ homeId })
  const postGuest = usePostGuest()
  const putGuest = usePutGuest()

  const fetchIntegrations = useFetchIntegrations({
    orgId: org.id,
    filters: { includeExternalIntegrations: true },
  })

  const extendedGuestsData = useMemo(() => {
    const guests: IGuestResponse[] = fetchGuests.data?.guests ?? []
    const integrations = fetchIntegrations.data?.integrations ?? []
    return extendGuestsData(integrations, guests)
  }, [fetchGuests.data?.guests, fetchIntegrations.data?.integrations])

  useEffect(() => {
    if (offset !== 0 && fetchGuests.data?.guests.length === 0) {
      //Handles the case where the last user on a page is deleted;
      //then we want to fetch the previous page of users
      setOffset((oldOffset) => Math.max(0, oldOffset - limit))
    }
  }, [fetchGuests.data?.guests.length, offset])

  function addClicked() {
    guestConnectAvailability.available
      ? setGuestSmsConfigDialogOpen(true)
      : setUpgradeBlockerDialogOpen(true)
  }

  function removeGuest(guest?: IGuestExtended) {
    if (!guest) return

    deleteGuest.mutate(guest.guest_id, {
      onSuccess: () => {
        handleClose()
      },
    })
  }

  function saveNewGuest(guest: IGuestPost) {
    postGuest.mutate(
      { guest, homeId },
      {
        onSuccess: () => {
          handleClose()
        },
      }
    )
  }

  function editGuest(data: IGuestPut) {
    if (!selectedGuest?.guest_id) {
      return
    }

    data.home_id = homeId

    putGuest.mutate(
      { guest: data },
      {
        onSuccess: () => {
          handleClose()
        },
      }
    )
  }

  function showRemoveConfirm() {
    if (!selectedGuest) {
      return
    }

    setRemoveConfirmDialogOpen(true)
  }

  function handleClose() {
    setRemoveConfirmDialogOpen(false)
    setGuestSmsConfigDialogOpen(false)
    setUpgradeBlockerDialogOpen(false)
    setSelectedGuest(undefined)
  }

  return (
    <>
      <TopBox>
        <header>
          <FeatureBlockerText
            hidden={!guestConnectAvailability.ready}
            isFeatureAvailable={guestConnectAvailability.available}
          >
            <MText variant="heading2">{t(langKeys.guest, { count: 2 })}</MText>
          </FeatureBlockerText>

          <MText variant="body" color="secondary">
            <ReactMarkdown>
              {t(langKeys.guests_group_settings_description)}
            </ReactMarkdown>
          </MText>
        </header>

        <div>
          <AddButton
            onClick={addClicked}
            size="small"
            disabled={!canEdit || !guestConnectAvailability.ready}
          >
            {t(langKeys.add_guest)}
          </AddButton>
        </div>
      </TopBox>

      {guestConnectAvailability.available && (
        <>
          {(fetchGuests.data?.guests && (
            <GuestTable
              onEdit={(data: IGuestExtended) => {
                setSelectedGuest(
                  extendedGuestsData.find((g) => g.guest_id === data.guest_id)
                )
                setGuestSmsConfigDialogOpen(true)
              }}
              onRemove={(data: IGuestExtended) => {
                setSelectedGuest(
                  extendedGuestsData.find((g) => g.guest_id === data.guest_id)
                )
                setRemoveConfirmDialogOpen(true)
              }}
              guests={extendedGuestsData}
              canEdit={!!canEdit}
              timezone={homeTz}
            />
          )) || <TableLoadingIndicator />}
        </>
      )}
      <PagerWrapper>
        <Pager
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchGuests.data?.paging.total_count}
        />
      </PagerWrapper>

      <GuestSMSConfigDialog
        open={guestSmsConfigDialogOpen}
        homeId={homeId}
        timezone={homeTz}
        onSave={saveNewGuest}
        onCancel={handleClose}
        loading={postGuest.isLoading || putGuest.isLoading}
        edit={!!selectedGuest}
        onRemove={showRemoveConfirm}
        onEdit={editGuest}
        guest={selectedGuest}
        key={selectedGuest?.guest_id ?? "add_guest"}
      />

      <UpgradeBlockerDialog
        context="guest_connect"
        open={upgradeBlockerDialogOpen}
        onClose={handleClose}
      />

      <ConfirmDialog
        open={removeConfirmDialogOpen}
        onClose={handleClose}
        title={t(langKeys.guests_remove_dialog_title, {
          guest_name: selectedGuest?.name ?? "",
        })}
        description={t(langKeys.guests_remove_dialog_description)}
        confirmButtonProps={{ color: "destructive" }}
        confirmLabel={t(langKeys.remove)}
        onConfirm={() => removeGuest(selectedGuest)}
        loading={deleteGuest.isLoading}
      />
    </>
  )
}

const TopBox = styled.div`
  margin-bottom: ${spacing.M};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${spacing.XL2};

  header {
    flex: 1;
  }
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`

const TableLoadingIndicator = () => (
  <div style={{ display: "flex" }}>
    <MCircularProgress size={30} />
  </div>
)
